var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"},on:{"wheel":_vm.handleScroll}},[_c('div',{class:[
         _vm.isScrollingDown
            ? 'nave'
            : '',
      ],attrs:{"id":"nav"}},[_vm._m(0),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/Home"}},[_vm._v("首页")])],1),_c('li',[_c('router-link',{attrs:{"to":"/Pricing"}},[_vm._v("定价")])],1),_c('li',[_c('router-link',{attrs:{"to":"/AboutUs"}},[_vm._v("关于我们")])],1),_c('li',[_c('router-link',{attrs:{"to":"/Privacy"}},[_vm._v("隐私政策")])],1)]),_vm._m(1)]),_c('div',{staticClass:"left_nave",on:{"click":_vm.top}}),_c('router-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs"},[_c('img',{attrs:{"src":require("./assets/ERP_logo.png"),"alt":""}}),_c('p',[_vm._v("南棠科技（深圳）有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"but"},[_c('button',[_vm._v(" 登录 ")]),_c('button',[_vm._v(" 注册 ")])])
}]

export { render, staticRenderFns }